import styles from "./path-info.module.css";
import { ReactComponent as SnowMobileIcon } from "../../assets/images/path-cat-snowmobiling.svg";
import { COLORS } from "../../constants/app.constants";
import { ReactComponent as CloseIcon } from "../../assets/images/close.svg";
import { replaceWithBr } from "./utils";
type Props = {
  name: string;
  description: string;
  zones: string[];
  isStandAlone: boolean;
  onClose: () => void;
};

export const SnowMobilePathInfo = ({
  name,
  description,
  zones,
  isStandAlone,
  onClose,
}: Props) => {
  const getZoneMetadata = (zone: string) => {
    return zone === "high" ? "Högzon" : "Lågzon";
  };
  const uniqueZones = zones.filter(
    (value, index, array) => array.indexOf(value) === index
  );
  const zoneMetadata = uniqueZones
    .map((zone) => getZoneMetadata(zone))
    .join(", ");
  const metaData = "Skoterpärla, " + zoneMetadata;
  return (
    <div
      className={
        isStandAlone
          ? styles.subPathInfoContainerStandAlone
          : styles.subPathInfoContainer
      }
      style={{ height: isStandAlone ? "400px" : "200px" }}
      id="sheet"
    >
      <div className={styles.header}>
        <div className={styles.handleWrapper} id="handle">
          <div className={styles.dragHandle} />
        </div>
        <div className={styles.headerInfoSection}>
          <div
            className={
              isStandAlone ? styles.iconContainer : styles.iconContainerMobile
            }
          >
            <SnowMobileIcon color={COLORS.PATHS.SNOWMOBILING} />
          </div>
          <div className={styles.headerRightCol}>
            <h1 className={isStandAlone ? styles.title : styles.titleMobile}>
              {name}
            </h1>
            <div className={styles.difficultyRow}>
              <div className={styles.difficultyText}>{metaData}</div>
            </div>
          </div>
          <CloseIcon className={styles.closeButton} onClick={onClose} />
        </div>
      </div>

      <div
        className={styles.snowmobilePathDescription}
        dangerouslySetInnerHTML={{
          __html: replaceWithBr(description),
        }}
      />
    </div>
  );
};
