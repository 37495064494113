export enum AppContentContext {
  Snowmobiling = "snowmobiling",
  SnowmobileTours = "snowmobile-tours",
  CrossCountrySkiing = "cross-country-skiing",
  MountainBiking = "mountain-biking",
  Hiking = "hiking",
  WinterHiking = "winter-hiking",
  Rombo = "rombo",
  Cultures = "cultures",
  SnowmobilePath = "snowmobile-path",
  SnowmobilingSubPath = "snowmobile-subpath",
  Fra = "fra",
}

export enum PathCategoryType {
  CrossCountrySkiing = "CATEGORY_1",
  Snowmobiling = "CATEGORY_2",
  MountainBiking = "CATEGORY_3",
  Hiking = "CATEGORY_4",
  WinterHiking = "CATEGORY_5",
  Rombo = "CATEGORY_6",
  Cultures = "CATEGORY_7",
}

export enum PathDifficulty {
  VeryEasy = "veryEasy",
  Easy = "easy",
  Medium = "medium",
  Hard = "hard",
}

export enum FilterIdentifier {
  Length = "length",
  Status = "status",
  Difficulty = "difficulty",
  Area = "area",
}

export enum PathStatusFilter {
  GroomedToday = "groomedToday",
  GroomedYesterdayAtMost = "groomedTesterdayAtMost",
}

export enum PathLengthFilter {
  ZeroToThree = "0-3",
  ThreeToSix = "3-6",
  SixToTen = "6-10",
  TenPlus = "10+",
}

export enum PathAreaFilter {
  Bruksvallarna = "bruksvallarna",
  Fjallnas = "fjällnäs",
  Funasdalen = "funäsdalen",
  Ljusnedal = "ljusnedal",
  Messlingen = "messlingen",
  Ramundberget = "ramundberget",
  Tanndalen = "tänndalen",
  Tannas = "tännäs",
}
