import { Component } from "react";
import AppContext from "../../context/app-context";
import classNames from "classnames";
import styles from "./trail-list.module.css";
import { FeatureType } from "../../../enums/map.enums";
import { TrailListEntry } from "./trail-list-entry/trail-list-entry.component";

export class TrailList extends Component {
  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;
  render() {
    const { mapSnowmobilePaths, highlightedId } = this.context;
    return (
      <div className={styles.listContainer}>
        {Object.values(mapSnowmobilePaths).map((path, index) => (
          <div key={path.id}>
            <TrailListEntry
              className={classNames({
                [styles.evenEntry]: index % 2 === 0,
                [styles.oddEntry]: index % 2 === 1,
                [styles.selectedEntry]: highlightedId === path.id.toString(),
              })}
              length={path.length}
              name={path.name}
              onClick={() => this.handleOnClick(path.id.toString())}
            />
          </div>
        ))}
        {!Object.values(mapSnowmobilePaths)?.length && (
          <div className={styles.empty}>Inga skoterturer hittades</div>
        )}
      </div>
    );
  }
  handleOnClick(id: string) {
    this.context.setSelected(FeatureType.SnowmobilePath, id);
  }
}
