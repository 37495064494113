import { COLORS } from "./app.constants";

export const MAP_SRC =
  "https://api.lantmateriet.se/open/topowebb-ccby/v1/wmts/token/016549e5-cc39-3abe-a778-467006e7cce1/?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=topowebb&STYLE=default&TILEMATRIXSET=3857&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&FORMAT=image%2Fpng";
export const SAT_SRC =
  "https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}";

export const ICONS = {
  PATHS: {
    CROSS_COUNTRY_SKIING: "images/openlayers/path-cat-cross-country-skiing.svg",
    CROSS_COUNTRY_SKIING_CLOSED:
      "images/openlayers/path-cat-cross-country-skiing-closed.svg",
    SNOWMOBILING: "images/openlayers/path-cat-snowmobiling.svg",
    MOUNTAIN_BIKING: "images/openlayers/path-cat-mountain-biking.svg",
    HIKING: "images/openlayers/path-cat-hiking.svg",
    WINTER_HIKING: "images/openlayers/path-cat-winter-hiking.svg",
    ROMBO: "images/openlayers/path-cat-rombo.svg",
    CULTURES: "images/openlayers/path-cat-hiking.svg",
    ENDCAP: "images/openlayers/path-endcap.svg",
  },
  POIS: {
    RESTAURANT: "images/openlayers/poi-restaurang.svg",
    CAFES: "images/openlayers/poi-fik-servering.svg",
    ATTRACTIONS: "images/openlayers/poi-sevardhet.svg",
    PARKING: "images/openlayers/poi-parkering.svg",
    LOOKOUTS: "images/openlayers/poi-utsiktsplats.svg",
    CABINS: "images/openlayers/poi-raststuga.svg",
    SKI_BUS: "images/openlayers/poi-skidbuss.svg",
    SHELTER: "images/openlayers/poi-vindskydd.svg",
    STORES: "images/openlayers/poi-butik.svg",
    BIKE_WASH: "images/openlayers/poi-cykeltvatt.svg",
    BREAK_CABIN: "images/openlayers/poi-raststuga.svg",
    SNOWMOBILE_RENTAL: "images/openlayers/poi-skoteruthyrning.svg",
    SPORTS_RENTAL: "images/openlayers/poi-sportuthyrning.svg",
    TOILETS: "images/openlayers/poi-toalett.svg",
    SKI_WAXING_SHED: "images/openlayers/poi-vallabod.svg",
    WARNINGS: "images/openlayers/poi-varning.svg",
    WEAK_ICE: "images/openlayers/poi-weak-ice.svg",
  },
  GPS_POSITION: "images/openlayers/gps-position.svg",
};

export const PROPS = {
  PATHS: {
    CATEGORY_1: {
      color: COLORS.PATHS.CROSS_COUNTRY_SKIING,
      icon: ICONS.PATHS.CROSS_COUNTRY_SKIING,
      closedIcon: ICONS.PATHS.CROSS_COUNTRY_SKIING_CLOSED,
    },
    CATEGORY_2: {
      color: COLORS.PATHS.SNOWMOBILING,
      icon: ICONS.PATHS.SNOWMOBILING,
      closedIcon: ICONS.PATHS.SNOWMOBILING,
    },
    CATEGORY_3: {
      color: COLORS.PATHS.MOUNTAIN_BIKING,
      icon: ICONS.PATHS.MOUNTAIN_BIKING,
      closedIcon: ICONS.PATHS.MOUNTAIN_BIKING,
    },
    CATEGORY_4: {
      color: COLORS.PATHS.HIKING,
      icon: ICONS.PATHS.HIKING,
      closedIcon: ICONS.PATHS.HIKING,
    },
    CATEGORY_5: {
      color: COLORS.PATHS.WINTER_HIKING,
      icon: ICONS.PATHS.WINTER_HIKING,
      closedIcon: ICONS.PATHS.WINTER_HIKING,
    },
    CATEGORY_6: {
      color: COLORS.PATHS.ROMBO,
      icon: ICONS.PATHS.ROMBO,
      closedIcon: ICONS.PATHS.ROMBO,
    },
    CATEGORY_7: {
      color: COLORS.PATHS.CULTURES,
      icon: ICONS.PATHS.CULTURES,
      closedIcon: ICONS.PATHS.CULTURES,
    },
  },
  POIS: {
    POI_CATEGORY_1: {
      color: COLORS.POIS.RESTAURANT,
      icon: ICONS.POIS.RESTAURANT,
      closedIcon: ICONS.POIS.RESTAURANT,
    },
    POI_CATEGORY_2: {
      color: COLORS.POIS.CAFES,
      icon: ICONS.POIS.CAFES,
      closedIcon: ICONS.POIS.CAFES,
    },
    POI_CATEGORY_3: {
      color: COLORS.POIS.ATTRACTIONS,
      icon: ICONS.POIS.ATTRACTIONS,
      closedIcon: ICONS.POIS.ATTRACTIONS,
    },
    POI_CATEGORY_4: {
      color: COLORS.POIS.LOOKOUTS,
      icon: ICONS.POIS.LOOKOUTS,
      closedIcon: ICONS.POIS.LOOKOUTS,
    },
    POI_CATEGORY_5: {
      color: COLORS.POIS.PARKING,
      icon: ICONS.POIS.PARKING,
      closedIcon: ICONS.POIS.PARKING,
    },
    POI_CATEGORY_6: {
      color: COLORS.POIS.CABINS,
      icon: ICONS.POIS.CABINS,
      closedIcon: ICONS.POIS.CABINS,
    },
    POI_CATEGORY_7: {
      color: COLORS.POIS.SKI_BUS,
      icon: ICONS.POIS.SKI_BUS,
      closedIcon: ICONS.POIS.SKI_BUS,
    },
    POI_CATEGORY_8: {
      // TODO: Allocate a separate color for shelters?
      color: COLORS.POIS.CABINS,
      icon: ICONS.POIS.SHELTER,
      closedIcon: ICONS.POIS.SHELTER,
    },
    POI_CATEGORY_9: {
      color: COLORS.POIS.STORES,
      icon: ICONS.POIS.STORES,
      closedIcon: ICONS.POIS.STORES,
    },
    POI_CATEGORY_10: {
      color: COLORS.POIS.BIKE_WASH,
      icon: ICONS.POIS.BIKE_WASH,
      closedIcon: ICONS.POIS.BIKE_WASH,
    },
    POI_CATEGORY_11: {
      // TODO: Allocate a separate color for break cabins?
      color: COLORS.POIS.CABINS,
      icon: ICONS.POIS.BREAK_CABIN,
      closedIcon: ICONS.POIS.BREAK_CABIN,
    },
    POI_CATEGORY_12: {
      color: COLORS.POIS.SNOWMOBILE_RENTAL,
      icon: ICONS.POIS.SNOWMOBILE_RENTAL,
      closedIcon: ICONS.POIS.SNOWMOBILE_RENTAL,
    },
    POI_CATEGORY_13: {
      color: COLORS.POIS.SPORTS_RENTAL,
      icon: ICONS.POIS.SPORTS_RENTAL,
      closedIcon: ICONS.POIS.SPORTS_RENTAL,
    },
    POI_CATEGORY_14: {
      color: COLORS.POIS.TOILETS,
      icon: ICONS.POIS.TOILETS,
      closedIcon: ICONS.POIS.TOILETS,
    },
    POI_CATEGORY_15: {
      color: COLORS.POIS.SKI_WAXING_SHED,
      icon: ICONS.POIS.SKI_WAXING_SHED,
      closedIcon: ICONS.POIS.SKI_WAXING_SHED,
    },
    POI_CATEGORY_16: {
      color: COLORS.POIS.WARNING,
      icon: ICONS.POIS.WARNINGS,
      closedIcon: ICONS.POIS.WARNINGS,
    },
    POI_CATEGORY_17: {
      color: COLORS.POIS.WEAK_ICE,
      icon: ICONS.POIS.WEAK_ICE,
      closedIcon: ICONS.POIS.WEAK_ICE,
    },
  },
};

export const OBJECT_TYPE = "objectType";
export const OBJECT_ID = "objectId";
