import styles from "./path-info.module.css";
import { ReactComponent as SnowMobileIcon } from "../../assets/images/path-cat-snowmobiling.svg";
import { COLORS } from "../../constants/app.constants";
import { ReactComponent as CloseIcon } from "../../assets/images/close.svg";

type Props = {
  isStandAlone: boolean;
  name: string;
  zone: string;
  status: string;
  onClose: () => void;
};

export const SubPathInfo = ({
  isStandAlone,
  name,
  zone,
  status,
  onClose,
}: Props) => {
  const statusColor = status === "closed" ? "#bd2c16" : undefined;
  const statusText = status === "closed" ? "Stängt" : "";

  const subPathMetadata = zone === "high" ? "Högzon" : "Lågzon";

  return (
    <div
      className={
        isStandAlone
          ? styles.subPathInfoContainerStandAlone
          : styles.subPathInfoContainer
      }
      id="sheet"
    >
      <div className={styles.header}>
        <div className={styles.handleWrapper} id="handle">
          <div className={styles.dragHandle} />
        </div>
        <div className={styles.headerInfoSection}>
          <div
            className={
              isStandAlone ? styles.iconContainer : styles.iconContainerMobile
            }
          >
            <SnowMobileIcon color={COLORS.PATHS.SNOWMOBILING} />
          </div>
          <div className={styles.headerRightCol}>
            <h1 className={isStandAlone ? styles.title : styles.titleMobile}>
              {name}
            </h1>
            <div className={styles.difficultyRow}>
              {/* <div className={styles.difficultyText}>{metadata}</div> */}
              <div className={styles.difficultyText}>{subPathMetadata}</div>
            </div>
            <div className={styles.statusRow}>
              {status.length > 0 && (
                <div
                  className={styles.statusText}
                  style={{ color: statusColor }}
                >
                  {statusText}
                </div>
              )}
            </div>
          </div>
          <CloseIcon className={styles.closeButton} onClick={onClose} />
        </div>
      </div>
    </div>
  );
};
