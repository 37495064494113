import { createContext } from "react";
import { MapType } from "../../enums/map.enums";
import { AppContextState } from "../../interfaces/context.interfaces";

const AppContext = createContext({
  // Data for list
  paths: {},
  snowmobileSubPaths: {},
  listFilters: [],
  activeListFilterIndexes: [],
  filteredPaths: [],
  filteredSnowmobileSubPaths: [],

  // Data for map
  mapPaths: {},
  mapPathCategories: {},
  mapPathGpsCoordinates: {},
  mapPois: {},
  mapPoiCategories: {},
  mapSnowmobilePaths: {},
  mapSnowmobileSubPaths: {},
  mapSnowmobileFras: [],
  focusedCategoryPaths: [],

  // Misc
  mapType: MapType.Map,
  menuToggled: false,
  renderedInApp: false,
  showScooterInfo: false,

  // Functions
  setSelected: () => {},
  setHighlighted: () => {},
  setMapFilters: () => {},
  setListFilters: () => {},
  setGpsCentered: () => {},
  setMapType: () => {},
  setMenuToggled: () => {},
  setRenderedInApp: () => {},
  setActiveListFilterIndexes: () => {},
  setFilteredPaths: () => {},
  setFilteredSnowmobileSubPaths: () => {},
  setFocusedCategoryPaths: () => {},
  setShowScooterInfo: () => {},
} as AppContextState);

export default AppContext;
