import { Component, ReactNode } from "react";
import AppContext from "../context/app-context";
import {
  getSinglePathData,
  getSingleSnowmobileSubPathData,
  getSingleSnowMobilePathData,
} from "../../utils/api.utls";
import {
  PathCategoryResponse,
  PathGpsCoordinateResponse,
  PathTagResponse,
} from "../../interfaces/backend/path.interface";
import { AppContentContext } from "../../enums/app.enums";
import { FeatureType } from "../../enums/map.enums";
import { SubPathInfo } from "./subpath-info.component";
import { SnowMobilePathInfo } from "./snowmobile-path-info.component";
import { StandardPathInfo } from "./standard-path-info.component";

type PathInfoProps = {
  isStandAlone: boolean;
};

export interface PathInfoState {
  id: string | undefined;
  name: string;
  number: string;
  length: number;
  difficulty: string;
  status: string;
  description: string;
  end: string;
  start: string;
  description_en: string;
  pathGpsCoordinates: PathGpsCoordinateResponse[];
  pathCategoryId: number | undefined;
  tags: PathTagResponse[];
  tagsRaw: any[];
  pathCategory?: PathCategoryResponse;
  zone?: string;
  zones?: string[];
  isVisible: boolean;
  startY: number;
  deltaY: number;
  startHeight: number;
  isDragging: boolean;
}

class PathInfo extends Component<PathInfoProps, PathInfoState> {
  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;
  private reversed = false;
  constructor(props: PathInfoProps) {
    super(props);
    this.state = {
      id: undefined,
      name: "",
      number: "",
      length: 0,
      difficulty: "",
      status: "",
      description: "",
      end: "",
      start: "",
      description_en: "",
      pathGpsCoordinates: [],
      pathCategoryId: undefined,
      tags: [],
      tagsRaw: [],
      pathCategory: undefined,
      isVisible: true,
      startY: 0,
      deltaY: 0,
      startHeight: 0,
      isDragging: false,
    };
  }

  componentDidMount(): void {
    const { renderedInApp, appContentContext, highlightedType, selectedType } =
      this.context;

    const isFra =
      selectedType === AppContentContext.Fra.toString() ||
      highlightedType === FeatureType.SnowmobileFra;
    const shouldRender = this.props.isStandAlone || renderedInApp;
    if (this.context.highlightedId && shouldRender && !isFra) {
      this.fetchData();
      this.show();
    }
  }
  componentDidUpdate(
    prevProps: Readonly<PathInfoProps>,
    prevState: Readonly<PathInfoState>,
    snapshot?: any
  ): void {
    const { renderedInApp, appContentContext, selectedType, highlightedType } =
      this.context;

    const shouldRender = this.props.isStandAlone || renderedInApp;

    const isFra =
      selectedType === AppContentContext.Fra.toString() ||
      appContentContext === AppContentContext.Fra.toString() ||
      selectedType === AppContentContext.Fra.toString() ||
      highlightedType === FeatureType.SnowmobileFra;

    if (
      this.context.highlightedId !== this.state.id &&
      shouldRender &&
      !isFra
    ) {
      this.fetchData();
      this.show();
    }

    const dragHandle = document.querySelector("#handle");

    if (!renderedInApp) {
      dragHandle?.addEventListener(
        "mousedown",
        this.startDragging as EventListener
      );
    } else {
      dragHandle?.addEventListener(
        "touchstart",
        this.startTouchDragging as EventListener
      );
    }
  }

  hide() {
    this.setState({ isVisible: false });
  }

  show() {
    this.setState({ isVisible: true });
  }

  startDragging = (e: MouseEvent) => {
    const bottomSheet = document.querySelector("#sheet") as HTMLElement;
    e.preventDefault();
    this.setState({
      startY: e.pageY,
      startHeight: parseInt(getComputedStyle(bottomSheet).height),
      deltaY: 0,
      isDragging: true,
    });
    document.addEventListener("mousemove", this.drag);
    document.addEventListener("mouseup", this.stopDragging);
  };
  drag = (e: MouseEvent) => {
    if (!this.state.isDragging) return;
    const bottomSheet = document.querySelector("#sheet") as HTMLElement;

    const { startY, startHeight } = this.state;

    const delta = startY - e.clientY;
    const newHeight = startHeight + delta;
    if (parseInt(getComputedStyle(bottomSheet).height) < 500) {
      bottomSheet.style.height = newHeight + "px";
    }
  };

  startTouchDragging = (e: TouchEvent) => {
    const bottomSheet = document.querySelector("#sheet") as HTMLElement;
    e.preventDefault();
    this.setState({
      startY: e.touches[0].pageY,
      startHeight: parseInt(getComputedStyle(bottomSheet).height),
      deltaY: 0,
      isDragging: true,
    });
    document.addEventListener("touchmove", this.dragTouch);
    document.addEventListener("touchend", this.stopDragging);
  };

  dragTouch = (e: TouchEvent) => {
    if (!this.state.isDragging) return;
    const { startY, startHeight } = this.state;

    const bottomSheet = document.querySelector("#sheet") as HTMLElement;
    const delta = startY - e.touches[0].clientY;
    const newHeight = startHeight + delta;
    bottomSheet.style.height = newHeight + "px";
  };

  stopDragging = () => {
    const { renderedInApp } = this.context;
    if (!renderedInApp) {
      document.removeEventListener("mousemove", this.drag);
      document.removeEventListener("mouseup", this.stopDragging);
    } else {
      document.removeEventListener("touchmove", this.dragTouch);
      document.removeEventListener("touchend", this.stopDragging);
    }
    this.setState({ isDragging: false });
  };

  fetchData() {
    const { highlightedId, selectedType } = this.context;
    const isSnowMobilingSubPath =
      selectedType === AppContentContext.SnowmobilingSubPath.toString();

    const isSnowMobilingPath =
      selectedType === AppContentContext.SnowmobilePath.toString();

    if (highlightedId) {
      if (isSnowMobilingSubPath) {
        Promise.resolve(
          getSingleSnowmobileSubPathData(highlightedId as string)
        ).then((path) => {
          this.setState({
            name: path.name,
            status: path.status,
            zone: path.zone,
          });
        });
      } else if (isSnowMobilingPath) {
        Promise.resolve(
          getSingleSnowMobilePathData(highlightedId as string)
        ).then((path) => {
          this.setState({
            name: path.name,
            description: path.description,
            length: path.length,
            zones: path.subPaths?.map((subPath) => subPath.zone),
          });
        });
      } else {
        Promise.resolve(getSinglePathData("paths", highlightedId)).then(
          (path) => {
            this.setState({
              name: path.name,
              number: path.number,
              length: path.length,
              difficulty: path.difficulty,
              description: path.description,
              description_en: path.description_en,
              end: path.end,
              start: path.start,
              pathGpsCoordinates: path.pathGpsCoordinates,
              pathCategoryId: path.pathCategoryId,
              tags: path.tags,
              tagsRaw: path.tagsRaw,
              pathCategory: path.pathCategory,
              status: path.status,
            });
          }
        );
      }
    }
    this.setState({ id: this.context.highlightedId });
  }

  render(): ReactNode {
    if (!this.state.isVisible) return null;
    const {
      mapPathCategories,
      highlightedType,
      highlightedId,
      selectedType,

      appContentContext,
      showScooterInfo,
    } = this.context;

    const showScooterWindows = showScooterInfo;

    const isSnowMobilingSubPath =
      selectedType === AppContentContext.SnowmobilingSubPath.toString();

    const isSnowMobilingPath =
      selectedType === AppContentContext.SnowmobilePath.toString();

    const isStandAlone = this.props.isStandAlone && window.innerWidth > 768;

    if (!highlightedId) {
      return <></>;
    }
    if (
      isSnowMobilingPath ||
      appContentContext === AppContentContext.SnowmobileTours
    ) {
      const { name, description, zones } = this.state;
      return (
        <SnowMobilePathInfo
          onClose={() => this.hide()}
          name={name}
          zones={zones || []}
          description={description}
          isStandAlone={this.props.isStandAlone}
        />
      );
    }

    if (isSnowMobilingSubPath) {
      return (
        showScooterWindows && (
          <SubPathInfo
            isStandAlone={this.props.isStandAlone}
            name={this.state.name}
            zone={this.state.zone as string}
            status={this.state.status}
            onClose={() => this.hide()}
          />
        )
      );
    }
    if (
      highlightedId &&
      highlightedType &&
      highlightedType !== FeatureType.Poi &&
      this.state.pathCategoryId
    ) {
      return (
        <StandardPathInfo
          state={{ ...this.state }}
          isStandAlone={isStandAlone}
          mapPathCategories={mapPathCategories}
          onClose={() => this.hide()}
        />
      );
    } else {
      return <></>;
    }
  }
}

export default PathInfo;
