import classNames from "classnames";
import styles from "./trail-list-entry.module.css";
import { COLORS } from "../../../../constants/app.constants";

import { ReactComponent as Icon } from "../../../../assets/images/path-cat-snowmobiling.svg";

type Props = {
  className: string;
  name: string;
  length: number;
  onClick: () => void;
};

export const TrailListEntry = ({ className, name, length, onClick }: Props) => {
  return (
    <div className={classNames(className, styles.listEntry)} onClick={onClick}>
      <div className={styles.container}>
        <div className={styles.iconContainer}>
          <Icon
            className={styles.icon}
            style={{ color: COLORS.PATHS.SNOWMOBILING }}
          />
        </div>

        <div className={styles.info}>
          <div className={styles.infoRow1}>
            <span className={styles.infoTitle}>{name}</span>
          </div>

          <div className={styles.infoRow2}>
            <div className={styles.infoMetadata}>
              <span className={styles.metadataText}>{length} km</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
