import { Component } from "react";
import {
  AppContentContext,
  FilterIdentifier,
  PathAreaFilter,
  PathDifficulty,
  PathLengthFilter,
  PathStatusFilter,
} from "../../enums/app.enums";
import { FeatureType, MapType, PathCategoryId } from "../../enums/map.enums";
import { AppContextState } from "../../interfaces/context.interfaces";
import { Filter } from "../../interfaces/filters.interface";
import {
  MapCategories,
  MapFilter,
  MapGpsCoordinate,
} from "../../interfaces/map/common.interfaces";
import {
  MapPathGpsCoordinates,
  MapPaths,
} from "../../interfaces/map/path.interface";
import { MapPois } from "../../interfaces/map/poi.interface";
import {
  MapSnowmobilePaths,
  MapSnowmobileSubPaths,
} from "../../interfaces/map/snowmobile-path.interface";
import { Path } from "../../interfaces/path.interface";
import { SnowmobileSubPath } from "../../interfaces/snowmobile-path.interface";
import {
  getPathData,
  getPoiData,
  getSnowmobileData,
  getSnowmobileFraData,
} from "../../utils/api.utls";
import {
  contextToPathCategoryId,
  toMapCategories,
  toMapPathGpsCoordinates,
  toMapPaths,
  toMapPois,
  toMapSnowmobilePaths,
  toMapSnowmobileSubPaths,
  toPaths,
  toSnowmobilFra,
  toSnowmobileSubPaths,
} from "../../utils/conversion.utils";
import { getPathDifficultyIcon } from "../../utils/rendering.utils";
import DataContext from "./app-context";
import { SnowmobileFraResponse } from "../../interfaces/backend/snowmobile-paths.interface";

type AppProviderProps = {
  //
};

class AppProvider extends Component<AppProviderProps, AppContextState> {
  constructor(props: AppProviderProps) {
    super(props);

    this.state = {
      // Data for list
      paths: {},
      snowmobileSubPaths: {},
      listFilters: [],
      activeListFilterIndexes: [],
      filteredPaths: [],
      filteredSnowmobileSubPaths: [],

      // Data for map
      mapPaths: {},
      mapPathCategories: {},
      mapPathGpsCoordinates: {},
      mapPois: {},
      mapPoiCategories: {},
      mapSnowmobilePaths: {},
      mapSnowmobileSubPaths: {},
      mapSnowmobileFras: [],
      focusedCategoryPaths: [],

      // Misc
      mapType: MapType.Map,
      menuToggled: true,
      renderedInApp: false,
      showScooterInfo: false,

      // Functions
      setSelected: (selectedType?: FeatureType, selectedId?: string) =>
        this.setState({ ...this.state, selectedType, selectedId }),
      setHighlighted: (highlightedType?: FeatureType, highlightedId?: string) =>
        this.setState({ ...this.state, highlightedType, highlightedId }),
      setMapFilters: (mapFilters?: MapFilter[]) =>
        this.setState({ ...this.state, mapFilters }),
      setListFilters: (listFilters: Filter[]) =>
        this.setState({ ...this.state, listFilters }),
      setGpsCentered: (gpsCentered: boolean) =>
        this.setState({ ...this.state, gpsCentered }),
      setMapType: (mapType: MapType) =>
        this.setState({ ...this.state, mapType }),
      setMenuToggled: (menuToggled: boolean) =>
        this.setState({ ...this.state, menuToggled }),
      setRenderedInApp: (renderedInApp: boolean) =>
        this.setState({ ...this.state, renderedInApp }),
      setActiveListFilterIndexes: (activeListFilterIndexes: number[]) =>
        this.setState({ ...this.state, activeListFilterIndexes }),
      setFilteredPaths: (filteredPaths: Path[]) =>
        this.setState({ ...this.state, filteredPaths }),
      setFilteredSnowmobileSubPaths: (
        filteredSnowmobileSubPaths: SnowmobileSubPath[]
      ) => this.setState({ ...this.state, filteredSnowmobileSubPaths }),
      setFocusedCategoryPaths: (focusedCategoryPaths?: MapFilter[]) =>
        this.setState({ ...this.state, focusedCategoryPaths }),
      setShowScooterInfo: (showScooterInfo: boolean) =>
        this.setState({ ...this.state, showScooterInfo }),
    };
  }

  componentDidMount() {
    if (process.env.REACT_APP_STANDALONE === "true") {
      const search = window.location.search;
      const params = new URLSearchParams(search);
      const context = params.get("context") || undefined;
      if (context) {
        this.setupListFilters();
        this.fetchData(context.toLowerCase());
      }
    }

    if (process.env.REACT_APP_EXPOSE_FUNCTIONS === "true") {
      // Window scope functions for external JS invocation
      (window as any).setPathData = (
        mapPathCategories: MapCategories,
        mapPaths: MapPaths,
        mapPathGpsCoordinates: MapPathGpsCoordinates
      ) =>
        this.setState({
          ...this.state,
          mapPathCategories,
          mapPaths,
          mapPathGpsCoordinates,
        });
      (window as any).setSnowmobilePathData = (
        mapSnowmobilePaths: MapSnowmobilePaths,
        mapSnowmobileSubPaths: MapSnowmobileSubPaths
      ) =>
        this.setState({
          ...this.state,
          mapSnowmobilePaths,
          mapSnowmobileSubPaths,
        });
      (window as any).setSnowmobileFraData = (
        mapSnowmobileFras: SnowmobileFraResponse[]
      ) =>
        this.setState({
          ...this.state,
          mapSnowmobileFras,
        });
      (window as any).setPoiData = (
        mapPoiCategories: MapCategories,
        mapPois: MapPois
      ) => this.setState({ ...this.state, mapPoiCategories, mapPois });
      (window as any).setFilters = (mapFilters?: MapFilter[]) =>
        this.setState({ ...this.state, mapFilters });
      (window as any).setSelected = (
        selectedType?: FeatureType,
        selectedId?: string
      ) => this.setState({ ...this.state, selectedType, selectedId });
      (window as any).setHighlighted = (
        highlightedType?: FeatureType,
        highlightedId?: string
      ) => this.setState({ ...this.state, highlightedType, highlightedId });
      (window as any).setGpsPosition = (gpsPosition: MapGpsCoordinate) =>
        this.setState({ ...this.state, gpsPosition });
      (window as any).setInitialState = (initialState: any) =>
        this.setState({ ...this.state, ...initialState });

      /*
            // TODO: Find a better way to exclude large amount of mock data from builds
            import('../../mock/paths.mock').then(({mockPathCategories, mockPaths, mockGpsCoordinates}) => {
                import('../../mock/pois.mock').then(({mockPoiCategories, mockPois}) => {
                    import ('../../mock/snowmobile-paths.mock').then(({mockSnowmobilePaths, mockSnowmobileSubPaths}) => {
                        (window as any).populateMockData = () => {
                            this.setState({
                                ...this.state,
                                mapPathCategories: mockPathCategories,
                                mapPaths: mockPaths,
                                mapPathGpsCoordinates: mockGpsCoordinates,
                                mapPoiCategories: mockPoiCategories,
                                mapPois: mockPois,
                                mapSnowmobilePaths: mockSnowmobilePaths,
                                mapSnowmobileSubPaths: mockSnowmobileSubPaths,
                            });

                            setTimeout(() => {
                                this.setState({
                                    ...this.state,
                                    selectedType: FeatureType.Path,
                                    mapFilters: [],
                                })
                            });
                        }
                    });
                });
            });*/
    }
  }

  render() {
    return (
      <DataContext.Provider
        value={{
          ...this.state,
        }}
      >
        {this.props.children}
      </DataContext.Provider>
    );
  }

  /**
   * Fetches the data required for a given app context and sets up the provider state for it
   */
  fetchData(context: string) {
    const promises: Promise<any>[] = [getPoiData()];
    switch (context) {
      case AppContentContext.Snowmobiling:
        promises.push(getSnowmobileData());
        break;
      case AppContentContext.SnowmobileTours:
        promises.push(getSnowmobileData());
        break;
      case AppContentContext.Fra:
        promises.push(getSnowmobileFraData());
        break;

      case AppContentContext.CrossCountrySkiing:
        promises.push(getPathData(PathCategoryId.CrossCountrySkiing));
        break;

      case AppContentContext.MountainBiking:
        promises.push(getPathData(PathCategoryId.MountainBiking));
        break;

      case AppContentContext.Hiking:
        promises.push(getPathData(PathCategoryId.Hiking));
        break;

      case AppContentContext.WinterHiking:
        promises.push(getPathData(PathCategoryId.WinterHiking));
        break;

      case AppContentContext.Rombo:
        promises.push(getPathData(PathCategoryId.Rombo));
        break;

      case AppContentContext.Cultures:
        promises.push(getPathData(PathCategoryId.Cultures));
        break;
      default:
        break;
    }

    Promise.all(promises).then((requirements) => {
      const [poiRequirements, pathRequirements] = requirements;
      const { pois, categories: poiCategories } = poiRequirements;
      const newData = {} as any;
      if (context) {
        const filteredPois = pois.filter(
          (poi: any) =>
            poi.pathCategories?.filter(
              (category: any) =>
                category.id ===
                contextToPathCategoryId(context as AppContentContext)
            ).length > 0
        );
        newData.mapPois = toMapPois(filteredPois);
      } else {
        newData.mapPois = toMapPois(pois);
      }

      newData.mapPoiCategories = toMapCategories(poiCategories);

      if (pathRequirements) {
        switch (context) {
          case AppContentContext.Snowmobiling:
            {
              const { paths, subPaths } = pathRequirements;
              newData.snowmobileSubPaths = toSnowmobileSubPaths(subPaths);
              newData.mapSnowmobilePaths = toMapSnowmobilePaths(paths);
              newData.mapSnowmobileSubPaths = toMapSnowmobileSubPaths(subPaths);
              newData.filteredSnowmobileSubPaths = Object.values(
                newData.snowmobileSubPaths
              );
              const { fras } = pathRequirements;
              newData.mapSnowmobileFras = fras;
            }
            break;
          case AppContentContext.SnowmobileTours:
            {
              const { paths, subPaths } = pathRequirements;
              newData.mapSnowmobilePaths = toMapSnowmobilePaths(paths);
              newData.mapSnowmobileSubPaths = toMapSnowmobileSubPaths(subPaths);
            }
            break;

          case AppContentContext.Fra:
            {
              const { fras } = pathRequirements;
              const convertedFras = toSnowmobilFra(fras);
              newData.mapSnowmobileFras = convertedFras;
            }
            break;

          case AppContentContext.CrossCountrySkiing:
          case AppContentContext.MountainBiking:
          case AppContentContext.Hiking:
          case AppContentContext.WinterHiking:
          case AppContentContext.Rombo:
          case AppContentContext.Cultures:
            {
              const {
                paths,
                categories: pathCategories,
                gpsCoordinates,
              } = pathRequirements;
              newData.paths = toPaths(paths);
              newData.mapPaths = toMapPaths(paths);
              newData.mapPathCategories = toMapCategories(pathCategories);
              newData.mapPathGpsCoordinates =
                toMapPathGpsCoordinates(gpsCoordinates);
              newData.filteredPaths = Object.values(newData.paths);
            }
            break;

          default:
            break;
        }
      }

      this.setState({
        ...this.state,
        ...newData,
      });

      setTimeout(() => {
        switch (context) {
          case AppContentContext.Snowmobiling:
            this.setState({
              ...this.state,
              selectedType: FeatureType.SnowmobilePath,
              appContentContext: AppContentContext.Snowmobiling,
              mapFilters: [],
            });
            break;

          case AppContentContext.Fra:
            this.setState({
              ...this.state,
              appContentContext: AppContentContext.Fra,
              mapFilters: [],
            });
            break;
          case AppContentContext.SnowmobileTours:
            this.setState({
              ...this.state,
              appContentContext: AppContentContext.SnowmobileTours,
              mapFilters: [],
            });
            break;

          case AppContentContext.CrossCountrySkiing:
          case AppContentContext.MountainBiking:
          case AppContentContext.Hiking:
          case AppContentContext.WinterHiking:
          case AppContentContext.Rombo:
          case AppContentContext.Cultures:
            this.setState({
              ...this.state,
              selectedType: FeatureType.Path,
              appContentContext: context as AppContentContext,
              mapFilters: [],
            });
            break;

          default:
            break;
        }
      });
    });
  }

  /**
   * Sets up the list filters
   */
  setupListFilters() {
    const lengthFilter: Filter = {
      identifier: FilterIdentifier.Length,
      placeholder: "Längd",
      filterValue: undefined,
      options: [
        { name: "Alla längder", value: undefined },
        { name: "0-3 km", value: PathLengthFilter.ZeroToThree },
        { name: "3-6 km", value: PathLengthFilter.ThreeToSix },
        { name: "6-10 km", value: PathLengthFilter.SixToTen },
        { name: "10+ km", value: PathLengthFilter.TenPlus },
      ],
    };

    // const statusFilter: Filter = {
    //     identifier: FilterIdentifier.Status,
    //     placeholder: 'Status',
    //     options: [
    //         {name: 'Alla statusar', value: undefined},
    //         {name: 'Preparerade idag', value: PathStatusFilter.GroomedToday},
    //         {name: 'Preparerade senast igår', value: PathStatusFilter.GroomedYesterdayAtMost},
    //     ],
    // };

    const difficultyFilter = {
      identifier: FilterIdentifier.Difficulty,
      placeholder: "Svårighet",
      options: [
        { name: "Alla svårighetsgrader", value: undefined },
        {
          name: "Mycket lätta",
          value: PathDifficulty.VeryEasy,
          icon: getPathDifficultyIcon(PathDifficulty.VeryEasy),
        },
        {
          name: "Lätta",
          value: PathDifficulty.Easy,
          icon: getPathDifficultyIcon(PathDifficulty.Easy),
        },
        {
          name: "Medelsvåra",
          value: PathDifficulty.Medium,
          icon: getPathDifficultyIcon(PathDifficulty.Medium),
        },
        {
          name: "Svåra",
          value: PathDifficulty.Hard,
          icon: getPathDifficultyIcon(PathDifficulty.Hard),
        },
      ],
    };

    const areaFilter = {
      identifier: FilterIdentifier.Area,
      placeholder: "Välj område",
      options: [
        { name: "Alla områden", value: undefined },
        { name: "Bruksvallarna", value: PathAreaFilter.Bruksvallarna },
        { name: "Fjällnäs", value: PathAreaFilter.Fjallnas },
        { name: "Funäsdalen", value: PathAreaFilter.Funasdalen },
        { name: "Ljusnedal", value: PathAreaFilter.Ljusnedal },
        { name: "Messlingen", value: PathAreaFilter.Messlingen },
        { name: "Ramundberget", value: PathAreaFilter.Ramundberget },
        { name: "Tänndalen", value: PathAreaFilter.Tanndalen },
        { name: "Tännäs", value: PathAreaFilter.Tannas },
      ],
    };

    const listFilters = [
      areaFilter,
      lengthFilter,
      // statusFilter,
      difficultyFilter,
    ];

    const activeListFilterIndexes = listFilters.map((filter: Filter) =>
      filter.options.map((option) => option.value).indexOf(filter.filterValue)
    );

    this.setState({
      ...this.state,
      listFilters,
      activeListFilterIndexes,
    });
  }
}

export default AppProvider;
