import { PathInfoState } from "./path-info.component";
import {
  getElevationGain,
  getMaxHeight,
  getMinHeight,
  getTotalElevation,
  getLineHeight,
  getTextHeight,
  difficultyMappings,
  formatLength,
  replaceWithBr,
} from "./utils";
import styles from "./path-info.module.css";
import {
  getPartialPathIcon,
  getPathColor,
  getPathDifficultyIcon,
} from "../../utils/rendering.utils";
import { MapCategories } from "../../interfaces/map/common.interfaces";
import { ReactComponent as CloseIcon } from "../../assets/images/close.svg";

type Props = {
  isStandAlone: boolean;
  state: PathInfoState;
  mapPathCategories: MapCategories;
  onClose: () => void;
};

export const StandardPathInfo = ({
  isStandAlone,
  state,
  mapPathCategories,
  onClose,
}: Props) => {
  const type = mapPathCategories[`${state.pathCategoryId}`]?.identifier;

  const Icon = (props: any) => getPartialPathIcon(type);
  const color = getPathColor(type);
  const DifficultyIcon = (props: any) =>
    getPathDifficultyIcon(state.difficulty, props);

  const renderIcon = () => (
    <>
      <Icon className={styles.icon} style={{ color: color }} />
      <span className={isStandAlone ? styles.iconText : styles.iconTextMobile}>
        {state.number}
      </span>
    </>
  );
  const elevationGain = getElevationGain(state.pathGpsCoordinates);

  const profileWidth = 7000;
  const profileHeight = 1800;
  const testMaxHeight = getMaxHeight(state.pathGpsCoordinates);
  const testMinHeight = getMinHeight(state.pathGpsCoordinates);
  const testTotalElevation = getTotalElevation(state.pathGpsCoordinates);
  const lineHeight = getLineHeight(
    testMinHeight,
    testMaxHeight,
    testTotalElevation
  );

  const textMaxHeight = getTextHeight(testMaxHeight);
  const textMinHeight = getTextHeight(testMinHeight);

  const difficultyName = difficultyMappings(state.difficulty);
  const metadata =
    state.tags && state.tags.length
      ? `${difficultyName}, ${state.tags.map((tag) => tag.name).join(", ")}`
      : difficultyName;

  const statusText = state.status === "closed" ? "Stängt" : "";
  const statusColor = state.status === "closed" ? "#bd2c16" : undefined;
  return (
    <div
      className={
        isStandAlone
          ? styles.pathInfoContainerStandAlone
          : styles.pathInfoContainer
      }
      id="sheet"
    >
      <div className={styles.header}>
        <div className={styles.handleWrapper} id="handle">
          <div className={styles.dragHandle} />
        </div>
        <div className={styles.headerInfoSection}>
          <div
            style={{ color: color }}
            className={
              isStandAlone ? styles.iconContainer : styles.iconContainerMobile
            }
          >
            {renderIcon()}
          </div>
          <div className={styles.headerRightCol}>
            <h1 className={isStandAlone ? styles.title : styles.titleMobile}>
              {state.name}
            </h1>
            <div className={styles.difficultyRow}>
              <DifficultyIcon
                className={
                  isStandAlone
                    ? styles.difficultyIcon
                    : styles.difficultyIconMobile
                }
              />
              <div className={styles.difficultyText}>{metadata}</div>
            </div>
            <div className={styles.statusRow}>
              <div className={styles.length}>{formatLength(state.length)}</div>
              <div className={styles.statusText}>
                {state.pathCategory?.name}
              </div>
              {statusText.length > 0 && (
                <>
                  <div className={styles.statusText}>-</div>
                  <div
                    className={styles.statusText}
                    style={{ color: statusColor }}
                  >
                    {statusText}
                  </div>
                </>
              )}
            </div>
          </div>
          <CloseIcon className={styles.closeButton} onClick={onClose} />
        </div>
      </div>

      <div
        style={{
          flex: 1,
          height: isStandAlone ? 200 : 110,
          marginBottom: !isStandAlone ? 0 : 5,
          marginLeft: !isStandAlone ? 16 : 0,
          marginRight: !isStandAlone ? 16 : 0,
        }}
      >
        <svg
          height={isStandAlone ? "200px" : "110px"}
          width={isStandAlone ? "100%" : "100%"}
          preserveAspectRatio="none"
          viewBox={`0 0 ${profileWidth} ${profileHeight}`}
          style={{ backgroundColor: "#D1E3EC" }}
        >
          <polyline
            fill="#82A996"
            stroke="#82A996"
            strokeWidth="3"
            points={`${state.pathGpsCoordinates?.map(
              (coord, index) =>
                `${
                  (profileWidth / state.pathGpsCoordinates.length +
                    profileWidth /
                      state.pathGpsCoordinates.length /
                      state.pathGpsCoordinates.length) *
                  index
                },
          ${
            profileHeight -
            350 -
            (coord.elevation - testMinHeight) *
              ((100 / (testTotalElevation + 100)) * 14)
          }`
            )},
        ${profileWidth}, ${profileHeight}, 0, ${profileHeight}`}
          />

          <line
            x1={0}
            y1={profileHeight - 350 - lineHeight}
            x2={profileWidth}
            y2={profileHeight - 350 - lineHeight}
            fill="rgba(153, 153, 153, .5)"
            stroke="rgba(153, 153, 153, .5)"
            strokeWidth="30"
            strokeLinecap="round"
            strokeDasharray="1, 80"
          />

          <text
            fill="#999999"
            stroke="#999999"
            strokeWidth="8"
            fontSize="215"
            fontStretch="normal"
            x={profileWidth - 40}
            y={profileHeight - 400 - lineHeight}
            textAnchor="end"
          >
            {textMaxHeight}
          </text>

          <line
            x1={0}
            y1={profileHeight - 350}
            x2={profileWidth}
            y2={profileHeight - 350}
            fill="rgba(255, 255, 255, .5)"
            stroke="rgba(255, 255, 255, .5)"
            strokeWidth="30"
            strokeLinecap="round"
            strokeDasharray="1, 80"
          />

          <text
            fill="#FFFFFF"
            stroke="#FFFFFF"
            strokeWidth="8"
            fontSize="215"
            fontStretch="normal"
            x={profileWidth - 40}
            y={profileHeight - 100}
            textAnchor="end"
          >
            {textMinHeight}
          </text>
        </svg>
      </div>
      <div className={styles.descriptionContainer}>
        <div className={styles.elevationText}>
          Total stigning: {elevationGain.toFixed(0)} m
        </div>
        <div
          className={styles.descriptionText}
          dangerouslySetInnerHTML={{
            __html: replaceWithBr(state.description),
          }}
        />
      </div>
    </div>
  );
};
